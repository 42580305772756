$timeline-line-color:  $color-blue-light-shade;
$timeline-circle-color:  $color-blue;
$timeline-item-circle-color: $color-blue;
$timeline-label-color: $color-white;
$timeline-item-line-color: $color-blue-light-shade;
$timeline-label-font-size: 14px;

$timeline-label-y-offset: -3px;
$timeline-label-x-offset: 10px;

$timeline-line-width: 10px;
$timeline-circle-diameter: 50px;
$timeline-event-min-height: 100px;
$timeline-event-margin: 10px;
$timeline-items-line-width: 5px;
$timeline-item-width: 150px;
$timeline-item-width-sm: 110px;
$timeline-item-padding: 50px;

$timeline-item-circle-diameter: 18px;
$timeline-item-padding-bottom: 15px;

$timeline-items-connector-y-offset: 12px;
$timeline-items-connector-x-offset: -2px;
$timeline-items-connector-angle: 25deg;

$timeline-start-padding: 20px;


@include responsive($sm) {

  .timeline {
    position: relative;
    padding-top: $timeline-start-padding;
    margin-bottom: 4rem;

    // Timeline line
    &:before {
      display: block;
      content: " ";
      background-color: $timeline-line-color;
      width: $timeline-line-width;
      height: 100%;
      position: absolute;
      top: 0;
      left: $timeline-circle-diameter * 0.5 - $timeline-line-width * 0.5 + 2px;
    }

    .timeline-event {
      position: relative;
      padding-left: $timeline-circle-diameter;
      margin-top: $timeline-event-margin;
      padding-top: 0px;
      padding-bottom: 30px;

      // Event circle
      &:before {
        display: block;
        content: " ";
        width: $timeline-circle-diameter;
        height: $timeline-circle-diameter;
        border-radius: 50%;
        background-color: $timeline-circle-color;
        position: absolute;
        bottom: 35px;
        left: 0;
        border: 2px solid $color-blue-light;
      }
      .timeline-event-label {
        color: $timeline-label-color;
        position: absolute;
        bottom: $timeline-circle-diameter * 0.5 - $timeline-label-font-size * 0.5 + $timeline-label-y-offset + 35px;
        left: $timeline-label-x-offset;
        font-size: $timeline-label-font-size;
        font-weight: bold;
      }
      .timeline-event-items {
        position: relative;
        // First item connector
        &:before {
          display: block;
          content: " ";
          width: $timeline-item-padding;
          border-bottom: $timeline-items-line-width dotted $timeline-item-line-color;
          position: absolute;
          bottom: $timeline-items-connector-y-offset;
          left: $timeline-items-connector-x-offset;
          transform: rotate($timeline-items-connector-angle);
        }

        .timeline-event-item {
          position: relative;
          display: inline-block;
          width: $timeline-item-width;
          box-sizing: content-box;
          padding-right: $timeline-item-padding;
          padding-bottom: $timeline-item-padding-bottom;
          border-bottom: $timeline-items-line-width dotted $timeline-item-line-color;
          @include specialFont;

          @include responsive($sm-md) {
            width: $timeline-item-width-sm;
            font-size: 12px;
          }

          &:first-child {
            margin-left: $timeline-item-padding;
          }

          &:last-child {
            border-bottom: none;
          }

          // Item bullet
          &:before {
            display: block;
            content: " ";
            width: $timeline-item-circle-diameter;
            height: $timeline-item-circle-diameter;
            border-radius: 50%;
            background-color: $timeline-item-circle-color;
            position: absolute;
            bottom: ($timeline-item-circle-diameter * -0.5) - $timeline-items-line-width * 0.5;
            left: ($timeline-item-circle-diameter * 0.05) * -7.5;
            border: 2px solid $color-blue-light;
          }
        }

        .timeline-event-item-wide {
          @include responsive($md) {
            width: 200px;
          }
          @include responsive($sm-only) {
            width: 150px;
          }
        }
      }
    }
  }
}

@include responsive($xs) {
  .timeline {
    margin-bottom: 50px;

    .timeline-event {
      position: relative;
      // Event circle
      &:before {
        display: block;
        content: " ";
        width: $timeline-circle-diameter;
        height: $timeline-circle-diameter;
        border-radius: 50%;
        background-color: $timeline-circle-color;
        position: absolute;
        top: 0;
        left: 0;
      }

      .timeline-event-label {
        position: absolute;
        top: 15px;
        left: 9px;
        color: $timeline-label-color;
        font-size: $timeline-label-font-size;
        font-weight: bold;
      }

      .timeline-event-items {

        border-left: 4px dotted $timeline-item-line-color;
        padding-left: 15px;
        margin: $timeline-circle-diameter * 0.5  $timeline-circle-diameter * 0.5 - 1   0;
        padding-top: 50px;
        margin-bottom: -25px;
        padding-bottom: 18px;

        .timeline-event-item {

          padding: 12px 0;
          position: relative;
          @include specialFont;

          // Bullets
          &:before {
            display: block;
            content: " ";
            width: $timeline-item-circle-diameter;
            height: $timeline-item-circle-diameter;
            border-radius: 50%;
            background-color: $timeline-circle-color;
            position: absolute;
            top: 14px;
            left: -26px;
          }
        }
      }
    }
  }
}
