.section {
  padding: 50px 0 50px;
}

.section-splash {
  height: 0;
  padding: 2% 0 40%;

  @include responsive($xs) {
    padding: 20% 0 60%;
  }
}

.section-light {
  background-color: $color-white;
}

.section-shade {
  background-color: $color-blue-light;
}

.section-gray {
  background-color: $color-gray-light;
}

.section-dark {
  background-color: $color-blue-dark;
}
