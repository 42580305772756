@mixin headingFont {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

@mixin specialFont {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}

@mixin lightFont {
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}
