$bullet-diameter: 12px;

ul.event-list {
  margin: 2rem 0 3rem;
  padding: 0;
  li {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    padding-left: 1.5em;
    margin-bottom: 0.5em;

    &:before {
      display: block;
      content: " ";
      width: $bullet-diameter;
      height: $bullet-diameter;
      border-radius: 50%;
      background-color: $color-blue;
      position: absolute;
      top: 4px;
      left: 0;
    }
    a {
      @include specialFont;
      color: $color-text;

      &:hover {
        color: $color-blue;
      }
    }
  }
}