$video-play-button-width: 80px;
$video-play-button-height: 60px;

.video-container {
  position: relative;

  .video-play {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    border: none;
    &:after {
      display: block;
      content: " ";
      width: $video-play-button-width;
      height: $video-play-button-height;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -0.5 * $video-play-button-height;
      margin-left: -0.5 * $video-play-button-width;
      background-color: $color-blue;
      background-image: url('../../img/ic-play.svg');
      background-position: 55% center;
      background-repeat: no-repeat;
      background-size: 50%;
      border: 2px solid $color-white;
    }

    &:hover {
      &:after {
        background-color: darken($color-blue, 5%);
        box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.7);
      }
    }

    &:focus {
      outline: none;
    }
  }

  .player-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .video-preview {
  }
}
