html, body {

}

body {

}

h1, h2, h3, h4, h5, h6 {
  @include headingFont;
}

h2 {
  font-size: 28px;
  text-shadow: rgba(0, 0, 0, 0.14) 3px 3px;
}

a {
  color: $color-blue;
}

a:focus, a:hover {
  color: darken($color-blue, 10%);
}

.btn-primary {
  background-image: linear-gradient(to bottom, $color-blue 0, darken($color-blue, 10%) 100%);

  &:focus, &:hover {
    background-color:  darken($color-blue, 10%);
  }
}

.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover {
  background-color: $color-blue;
}

.text-negative {
  @include neg;
  a {
    color: $color-white;
    font-weight: bold;
  }
  a.mailto {
    color: $color-white;
    font-weight: normal;
    &:hover {
      color: $color-white;
      text-decoration: underline;
    }
  }
}

.placeholder {
  background-color: $color-gray-light;
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  vertical-align: center;
  margin-bottom: 5px;
}

.placeholder-3_4 {
  height: 0;
  padding-bottom: 75%;
}

.placeholder-16_9 {
  height: 0;
  padding-bottom: 56.25%;
}

.mid {
  font-size: 16px;
}

.lead {
  @include lightFont;
  font-weight: 300;
}

.lead-large {
  @include lightFont;
  font-weight: 300;
  font-size: 2.3em;

  @include responsive($xs) {
    font-size: 1.7em;
  }
}

.img-responsive {
  @include responsive($xs) {
    margin: 20px 0 0;
  }
}
