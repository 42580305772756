/*************************************************/
/* This file was automatically generated by sass */
/* Don't edit it directly                        */
/*************************************************/
/* http://sonspring.com/journal/clearing-floats */
.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

/* Clearfix */
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* For IE 6/7 only */
.clearfix {
  *zoom: 1;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  font-size: 28px;
  text-shadow: rgba(0, 0, 0, 0.14) 3px 3px;
}

a {
  color: #0a9eaa;
}

a:focus, a:hover {
  color: #07717a;
}

.btn-primary {
  background-image: linear-gradient(to bottom, #0a9eaa 0, #07717a 100%);
}
.btn-primary:focus, .btn-primary:hover {
  background-color: #07717a;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  background-color: #0a9eaa;
}

.text-negative {
  color: #fff;
  -webkit-font-smoothing: antialiased !important;
}
.text-negative a {
  color: #fff;
  font-weight: bold;
}
.text-negative a.mailto {
  color: #fff;
  font-weight: normal;
}
.text-negative a.mailto:hover {
  color: #fff;
  text-decoration: underline;
}

.placeholder {
  background-color: #e4e4e4;
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  vertical-align: center;
  margin-bottom: 5px;
}

.placeholder-3_4 {
  height: 0;
  padding-bottom: 75%;
}

.placeholder-16_9 {
  height: 0;
  padding-bottom: 56.25%;
}

.mid {
  font-size: 16px;
}

.lead {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-weight: 300;
}

.lead-large {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-weight: 300;
  font-size: 2.3em;
}
@media screen and (max-width: 767px) {
  .lead-large {
    font-size: 1.7em;
  }
}

@media screen and (max-width: 767px) {
  .img-responsive {
    margin: 20px 0 0;
  }
}

.video-container {
  position: relative;
}
.video-container .video-play {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  border: none;
}
.video-container .video-play:after {
  display: block;
  content: " ";
  width: 80px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -40px;
  background-color: #0a9eaa;
  background-image: url("../../img/ic-play.svg");
  background-position: 55% center;
  background-repeat: no-repeat;
  background-size: 50%;
  border: 2px solid #fff;
}
.video-container .video-play:hover:after {
  background-color: #098892;
  box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.7);
}
.video-container .video-play:focus {
  outline: none;
}
.video-container .player-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
ul.event-list {
  margin: 2rem 0 3rem;
  padding: 0;
}
ul.event-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 0.5em;
}
ul.event-list li:before {
  display: block;
  content: " ";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #0a9eaa;
  position: absolute;
  top: 4px;
  left: 0;
}
ul.event-list li a {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  color: #000;
}
ul.event-list li a:hover {
  color: #0a9eaa;
}

@media screen and (min-width: 768px) {
  .timeline {
    position: relative;
    padding-top: 20px;
    margin-bottom: 4rem;
  }
  .timeline:before {
    display: block;
    content: " ";
    background-color: #79a6bb;
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 22px;
  }
  .timeline .timeline-event {
    position: relative;
    padding-left: 50px;
    margin-top: 10px;
    padding-top: 0px;
    padding-bottom: 30px;
  }
  .timeline .timeline-event:before {
    display: block;
    content: " ";
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #0a9eaa;
    position: absolute;
    bottom: 35px;
    left: 0;
    border: 2px solid #bbf5fb;
  }
  .timeline .timeline-event .timeline-event-label {
    color: #fff;
    position: absolute;
    bottom: 50px;
    left: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  .timeline .timeline-event .timeline-event-items {
    position: relative;
  }
  .timeline .timeline-event .timeline-event-items:before {
    display: block;
    content: " ";
    width: 50px;
    border-bottom: 5px dotted #79a6bb;
    position: absolute;
    bottom: 12px;
    left: -2px;
    transform: rotate(25deg);
  }
  .timeline .timeline-event .timeline-event-items .timeline-event-item {
    position: relative;
    display: inline-block;
    width: 150px;
    box-sizing: content-box;
    padding-right: 50px;
    padding-bottom: 15px;
    border-bottom: 5px dotted #79a6bb;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1199px) {
  .timeline .timeline-event .timeline-event-items .timeline-event-item {
    width: 110px;
    font-size: 12px;
  }
}
@media screen and (min-width: 768px) {
  .timeline .timeline-event .timeline-event-items .timeline-event-item:first-child {
    margin-left: 50px;
  }
  .timeline .timeline-event .timeline-event-items .timeline-event-item:last-child {
    border-bottom: none;
  }
  .timeline .timeline-event .timeline-event-items .timeline-event-item:before {
    display: block;
    content: " ";
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #0a9eaa;
    position: absolute;
    bottom: -11.5px;
    left: -6.75px;
    border: 2px solid #bbf5fb;
  }
}
@media screen and (min-width: 768px) and (min-width: 992px) {
  .timeline .timeline-event .timeline-event-items .timeline-event-item-wide {
    width: 200px;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
  .timeline .timeline-event .timeline-event-items .timeline-event-item-wide {
    width: 150px;
  }
}
@media screen and (max-width: 767px) {
  .timeline {
    margin-bottom: 50px;
  }
  .timeline .timeline-event {
    position: relative;
  }
  .timeline .timeline-event:before {
    display: block;
    content: " ";
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #0a9eaa;
    position: absolute;
    top: 0;
    left: 0;
  }
  .timeline .timeline-event .timeline-event-label {
    position: absolute;
    top: 15px;
    left: 9px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
  .timeline .timeline-event .timeline-event-items {
    border-left: 4px dotted #79a6bb;
    padding-left: 15px;
    margin: 25px 24px 0;
    padding-top: 50px;
    margin-bottom: -25px;
    padding-bottom: 18px;
  }
  .timeline .timeline-event .timeline-event-items .timeline-event-item {
    padding: 12px 0;
    position: relative;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
  }
  .timeline .timeline-event .timeline-event-items .timeline-event-item:before {
    display: block;
    content: " ";
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #0a9eaa;
    position: absolute;
    top: 14px;
    left: -26px;
  }
}
.section {
  padding: 50px 0 50px;
}

.section-splash {
  height: 0;
  padding: 2% 0 40%;
}
@media screen and (max-width: 767px) {
  .section-splash {
    padding: 20% 0 60%;
  }
}

.section-light {
  background-color: #fff;
}

.section-shade {
  background-color: #bbf5fb;
}

.section-gray {
  background-color: #e4e4e4;
}

.section-dark {
  background-color: #1f4e78;
}

h1.logo {
  display: block;
  text-indent: -100em;
  white-space: nowrap;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url("../../img/lg-2019-color.png");
  width: 1424px;
  height: 363px;
  width: 80%;
  height: 0;
  padding-bottom: 25.5%;
  background-size: contain;
  background-position: center center;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  h1.logo {
    width: 100%;
    padding-bottom: 24%;
  }
}

.nav-lang-switcher {
  margin-bottom: 30px;
  margin-top: -30px;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-weight: 300;
  font-size: 18px;
}

.bulletpoint-icon {
  width: 100%;
  height: 0;
  padding-bottom: 58%;
  font-size: 110px;
  line-height: 150px;
  color: #0a9eaa;
  text-align: center;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .bulletpoint-icon {
    width: 20%;
    float: left;
    clear: left;
    padding-bottom: 0;
    font-size: 30px;
    line-height: 20px;
    height: 66px;
    text-align: right;
    padding-right: 3%;
  }
}

.bulletpoint-text {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
}
.bulletpoint-text strong {
  color: #0a9eaa;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .bulletpoint-text {
    width: 80%;
    float: left;
    text-align: left;
    padding-right: 20%;
  }
}

.partner-bio {
  margin-bottom: 40px;
}

.partner-logo {
  height: 136px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 40px;
}
@media screen and (max-width: 991px) {
  .partner-logo {
    margin-bottom: 20px;
  }
}
.partner-logo img {
  display: inline-block;
  max-height: 100%;
}
@media screen and (max-width: 991px) {
  .partner-logo img {
    margin-top: 0 !important;
  }
}
.partner-logo.partner-logo-holo_light img {
  max-height: 85%;
  margin-top: 8.4%;
}
.partner-logo.partner-logo-imaginary img {
  max-height: 89%;
  margin-top: 4.8%;
}
.partner-logo.partner-logo-onemore img {
  margin-top: 2.5%;
  max-height: 93%;
}
.partner-logo.partner-logo-busterwood img {
  margin-top: 30px !important;
  margin-bottom: 30px;
  max-height: 65%;
}
.partner-logo.partner-logo-cri img {
  padding: 12px 0;
}
.partner-logo.partner-logo-kouz img {
  max-height: 50%;
  margin-top: 8%;
}
@media screen and (max-width: 767px) {
  .partner-logo.partner-logo-universcience {
    max-height: 50px;
    margin-top: 5%;
  }
}
.partner-logo.partner-logo-universcience img {
  margin-top: 13%;
  max-width: 77%;
}
@media screen and (min-width: 768px) {
  .partner-logo.partner-logo-universcience img {
    margin-top: 13% !important;
  }
}
.partner-logo.partner-logo-holoforge img {
  max-height: 80%;
  margin-top: 3%;
}

.partner-logo-wrapper {
  text-align: center;
}

.contact-data {
  margin-bottom: 20px;
}

.hyperspace {
  position: relative;
}
.hyperspace .fog {
  z-index: 50;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(#1f4e78 15%, rgba(31, 78, 120, 0) 90%);
}
.hyperspace .container {
  z-index: 100;
  position: relative;
}
.hyperspace canvas {
  z-index: 20;
}

.file-pdf {
  background-image: url("../../img/ic-pdf.png");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 36px auto;
  min-height: 36px;
  display: block;
  padding: 30px 30px 30px 60px;
  font-size: 20px;
  line-height: 20px;
  margin: 2rem 0;
}

.file-pdf-inline {
  display: inline-block;
  margin: 0;
  padding: 0.4em 0.2em 0.4em 1.9em;
  background-size: 24px;
  font-size: 1.2em;
  background-position: 0 center;
}

.section-banner-home {
  background-image: url("../../img/banner-2019.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0 -20px 20px -10px rgba(0, 0, 0, 0.8);
  background-color: black !important;
}

.img-hololens {
  margin-top: -2em;
}
@media screen and (max-width: 767px) {
  .img-hololens {
    margin-top: 3em;
  }
}