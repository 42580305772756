h1.logo {
  @include image-replace;
  @include image-replace-src('../../img/lg-2019-color.png', 1424px, 363px);
  width: 80%;
  height: 0;
  padding-bottom: 25.5%;
  background-size: contain;
  background-position: center center;
  margin: 0 auto;

  @include responsive($xs) {
    width: 100%;
    padding-bottom: 24%;
  }
}

.nav-lang-switcher {
  margin-bottom: 30px;
  margin-top: -30px;
  @include lightFont;
  font-weight: 300;
  font-size: 18px;
}

.bulletpoint-icon {
  width: 100%;
  height: 0;
  padding-bottom: 58%;
  font-size: 110px;
  line-height: 150px;
  color: $color-blue;
  text-align: center;

  @include responsive($sm-only) {
    width: 20%;
    float: left;
    clear: left;
    padding-bottom: 0;
    font-size: 30px;
    line-height: 20px;
    height: 66px;
    text-align: right;
    padding-right: 3%;
  }
}

.bulletpoint-text {
  @include lightFont;
  font-size: 18px;
  text-align: center;
  strong {
    color: $color-blue;
  }

  @include responsive($sm-only) {
    width: 80%;
    float: left;
    text-align: left;
    padding-right: 20%;
  }
}

.partner-bio {
  margin-bottom: 40px;
}

.partner-logo {
  height: 136px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 40px;

  @include responsive($xs-sm) {
    margin-bottom: 20px;
  }

  img {
    display: inline-block;
    max-height: 100%;

    @include responsive($xs-sm) {
      margin-top: 0 !important;
    }
  }

  &.partner-logo-holo_light img {
    max-height: 85%;
    margin-top: 8.4%;
  }

  &.partner-logo-imaginary img {
    max-height: 89%;
    margin-top: 4.8%;
  }

  &.partner-logo-onemore img {
    margin-top: 2.5%;
    max-height: 93%;
  }

  &.partner-logo-busterwood img {
    margin-top: 30px !important;
    margin-bottom: 30px;
    max-height: 65%;
  }

  &.partner-logo-cri img {
    padding: 12px 0;
  }

  &.partner-logo-kouz img {
    max-height: 50%;
    margin-top: 8%;
  }

  &.partner-logo-universcience {
    @include responsive($xs) {
      max-height: 50px;
      margin-top: 5%;
    }
  }

  &.partner-logo-universcience img {
    margin-top: 13%;
    max-width: 77%;

    @include responsive($sm) {
      margin-top: 13% !important;
    }
  }

  &.partner-logo-holoforge img {
    max-height: 80%;
    margin-top: 3%;
  }
}

.partner-logo-wrapper {
  text-align: center;
}

.contact-data {
  margin-bottom: 20px;
}

.hyperspace {
  position: relative;
  .fog {
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient($color-blue-dark 15%, rgba($color-blue-dark, 0) 90%);
  }
  .container {
    z-index: 100;
    position: relative;
  }
  canvas {
    z-index: 20;
  }
}

.file-pdf {
  background-image: url('../../img/ic-pdf.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 36px auto;
  min-height: 36px;
  display: block;
  padding: 30px 30px 30px 60px;
  font-size: 20px;
  line-height: 20px;
  margin: 2rem 0;
}

.file-pdf-inline {
  display: inline-block;
  margin: 0;
  padding: 0.4em 0.2em 0.4em 1.9em;
  background-size: 24px;
  font-size: 1.2em;
  background-position: 0 center;
}

.section-banner-home {
  background-image: url('../../img/banner-2019.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0 -20px 20px -10px rgba(0, 0, 0, 0.8);
  background-color: black !important;
}

.img-hololens {
  margin-top: -2em;
  @include responsive($xs) {
    margin-top: 3em;
  }
}
