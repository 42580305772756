$color-white-real: #fff;
$color-black-real: #000;

$color-white: $color-white-real;
$color-black: $color-black-real;
$color-gray-light: #e4e4e4;
$color-gray: #8e8e8e;

$color-blue-very-light: #e5ebf1;
$color-blue-light: #bbf5fb;
$color-blue-light-shade: #79a6bb;
$color-blue-light-highlight: #5fbcc5;
$color-blue: #0a9eaa;
$color-blue-dark: #1f4e78;

$color-orange-light: #ccb87e;

$color-background: $color-white;
$color-text: $color-black;
$color-link: $color-black;
$color-link-hover: $color-black;

@mixin neg {
  color: $color-white;
  -webkit-font-smoothing: antialiased !important;
}
